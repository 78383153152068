import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import IconCalendar from 'images/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';

const Container = styled.div`
  width: 100%;
  height: 52px;
  background-color: #ffffff;
  border: 1px solid #5fd2da;
  border-radius: 3px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  position: relative;

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #3b3516;
  }

  &::after {
    content: '';
    background-image: url(${IconCalendar});
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
  }
`;

const InputDate = (props) => {
  return (
    <Container>
      <DatePicker {...props} />
    </Container>
  );
};

export default InputDate;
