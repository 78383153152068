import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const StepBall = styled.div`
  width: 16px;
  height: 16px;
  background-color: #d8d8d8;
  border: 3px solid #e3e3e3;
  border-radius: 50%;
`;

const StepLine = styled.div`
  width: 220px;
  height: 3px;
  background-color: #5fd2da;
  border-radius: 50px;
  position: relative;
  top: 6px;
  flex: 1;
`;

const StepText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #b9b9b9;
`;

const Step = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  gap: 9px 0;

  ${(props) => {
    if (props.status < 0) {
      return css`
        ${StepText} {
          font-weight: 400;
          color: #b9b9b9;
        }

        ${StepBall} {
          background-color: #d8d8d8;
          border: 3px solid #e3e3e3;
        }
      `;
    }
    if (props.status === 0) {
      return css`
        ${StepText} {
          font-weight: 700;
          color: #3b3516;
        }

        ${StepBall} {
          background-color: #5fd2da;
          border: 3px solid #63dbe3;
        }
      `;
    }
    if (props.status > 0) {
      return css`
        ${StepText} {
          font-weight: 400;
          color: #3b3516;
        }

        ${StepBall} {
          background-color: #5fd2da;
          border: 3px solid #63dbe3;
        }
      `;
    }
  }}
`;

const Steps = (props) => {
  const { className, current, steps } = props;

  return (
    <Container className={className}>
      {steps.map((step, idx) => {
        const stepIdx = idx + 1;
        return (
          <>
            {stepIdx > 1 && <StepLine />}
            <Step status={current - stepIdx}>
              <StepBall />
              <StepText>{step}</StepText>
            </Step>
          </>
        );
      })}
    </Container>
  );
};

export default Steps;
