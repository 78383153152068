import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import Steps from 'components/UI/Steps';
import Input from 'components/UI/Input';
import InputPassword from 'components/UI/Input/InputPassword';
import InputDate from 'components/UI/Input/InputDate';
import Select from 'components/UI/Select';
import { Checkbox } from 'components/UI/Checkbox';

import { md } from 'utils/breakpoints';

import IconLogo from 'images/logo.svg';

const Container = styled.div`
  width: 482px;
  min-height: 710px;
  background-color: #fff;
  border-radius: 8px;
  padding: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  @media (max-width: ${md}) {
    width: 100vw;
    height: 100%;
    min-height: auto;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
  }

  ${(props) =>
    props.step === 2 &&
    css`
      margin: 100px auto;
    `}
`;

const TitleBar = styled.div`
  text-align: center;
  margin-bottom: 34px;
  z-index: 1000;

  @media (max-width: ${md}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 0;
  }
`;

const StyledIconLogo = styled.img`
  display: none;
  width: 28px;
  height: 30px;

  @media (max-width: ${md}) {
    display: block;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StepsWrapper = styled.div`
  margin-bottom: 24px;
`;

const ErrorText = styled.div`
  display: none;
  color: #fd5151;
  font-size: 14px;
  font-weight: 400;
`;

const RuleText = styled.div`
  &.password {
    font-size: 14px;
    font-weight: 400;
    color: #8185ca;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  margin-bottom: 40px;
`;

const FormItem = styled.div`
  &.password {
    ${(props) =>
      props.error &&
      css`
        ${ErrorText} {
          display: block;
        }

        input {
          border: 1px solid #fd5151;
        }
      `}
  }

  &.name {
    ${RuleText} {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
`;

const FormItemLabel = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  display: flex;
  justify-content: space-between;
`;

const Btn = styled.div`
  width: 100%;
  height: 58px;
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`;

const BtnGroup = styled.div`
  margin-top: auto;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 106px;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    padding: 24px 15px;
  }
`;

const SelectGroup = styled.div`
  display: flex;
  gap: 0 8px;

  > div {
    flex: 1;
  }
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000000;

  a {
    color: #5fd2da;
  }
`;

const Content = styled.div`
  overflow-y: scroll;

  @media (max-width: ${md}) {
    padding: 86px 16px 106px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const steps = ['密碼設定', '基本資料'];

const SignUp = (props) => {
  const [step, setStep] = useState(2);
  const [password, setPassword] = useState('');

  const [startDate, setStartDate] = useState(new Date());

  return (
    <Container step={step}>
      <TitleBar>
        <StyledIconLogo src={IconLogo} />
        <Title>註冊會員</Title>
      </TitleBar>
      <Content>
        <StepsWrapper>
          <Steps current={step} steps={steps} />
        </StepsWrapper>
        {step === 1 && (
          <Form>
            <FormItem>
              <FormItemLabel>帳號</FormItemLabel>
              <Input placeholder="請輸入帳號" />
            </FormItem>
            <FormItem className="password">
              <FormItemLabel>
                設定密碼<ErrorText>無效密碼格式</ErrorText>
              </FormItemLabel>
              <InputPassword
                placeholder="請輸入密碼"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormItem>
            <RuleText className="password">
              英文字母大小寫加上數字，例AAbbcc123456共8-20位英數字
            </RuleText>
            <FormItem className="password" error>
              <FormItemLabel>
                再次輸入密碼<ErrorText>無效密碼格式</ErrorText>
              </FormItemLabel>
              <InputPassword
                placeholder="請輸入密碼"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormItem>
          </Form>
        )}
        {step === 2 && (
          <Form>
            <FormItem className="name">
              <FormItemLabel>姓名</FormItemLabel>
              <Input placeholder="請輸入姓名" />
              <RuleText>僅接受中英文和數字或空白的混合輸入</RuleText>
            </FormItem>
            <FormItem>
              <FormItemLabel>生日</FormItemLabel>
              <InputDate
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </FormItem>
            <FormItem>
              <FormItemLabel>身分證字號/居留證號碼</FormItemLabel>
              <Input placeholder="請輸入身分證字號/居留證號碼" />
            </FormItem>
            <FormItem>
              <FormItemLabel>Email(選項)</FormItemLabel>
              <Input placeholder="請輸入E-mail" />
            </FormItem>
            <FormItem>
              <FormItemLabel>縣市/行政區(選填)</FormItemLabel>
              <SelectGroup>
                <Select placeholder="請選擇縣市" />
                <Select placeholder="請選擇行政區" />
              </SelectGroup>
            </FormItem>
            <FormItem>
              <FormItemLabel>詳細地址(選項)</FormItemLabel>
              <Input placeholder="請輸入詳細地址" />
            </FormItem>
            <FormItem>
              <Checkbox>
                <Text>
                  我同意家樂福關係企業與合作特約廠商個人資料蒐集、處理及利用告知及同意事項
                  <Link to="/">(請詳閱條款內容)</Link>
                </Text>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Checkbox>
                <Text>我願意收到電子報並享多重會員折扣訊息</Text>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Text>
                註冊即表示你同意<Link to="/">數位虛擬卡會員條款</Link>
                且建立虛擬含員好康卡，Carrefour Wallet{' '}
                <Link to="/">錢包使用者同意條款</Link>
              </Text>
            </FormItem>
          </Form>
        )}
      </Content>
      <BtnGroup>
        <Btn>{step === steps.length ? '新增' : '下一步'}</Btn>
      </BtnGroup>
    </Container>
  );
};

export default SignUp;
